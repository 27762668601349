export const streamerHomeRoute = () => '/' as const;

export const advertiserHomeRoute = () => '/advertiser' as const;

export const holidays2024Route = () => '/holidays2024' as const;

export const privacyPolicyRoute = () => '/privacy-policy' as const;

export const tosRoute = () => '/terms-of-service' as const;

export const brandFormRoute = () => '/brand-form' as const;

export const thankYouRoute = () => '/thank-you' as const;

export const twitchconRoute = () => '/twitchcon' as const;

export const robotsTxtRoute = () => '/robots.txt' as const;

export const sitemapRoute = () => '/sitemap.xml' as const;

export const campaignLinkRoute = (linkId: string, slug: string) =>
  `/l/${linkId}/${slug}` as const;

export const obsOverlayRoute = (streamerPublicId: string) =>
  `/api/obs/${streamerPublicId}/overlay` as const;

export const obsOverlayNextCampaignRoute = (streamerPublicId: string) =>
  `/api/obs/${streamerPublicId}/campaigns/next` as const;

export const obsOverlayCampaignsRoute = (streamerPublicId: string) =>
  `/api/obs/${streamerPublicId}/campaigns`;

export const obsOverlayVideoRoute = (
  streamerPublicId: string,
  campaignPublicId: string,
  fingerprint: string
) =>
  `${obsOverlayCampaignsRoute(streamerPublicId)}/${campaignPublicId}/video/${fingerprint}`;

export const obsOverlayEventsRoute = (streamerPublicId: string) =>
  `/api/obs/${streamerPublicId}/events` as const;

export const publicRoutes = {
  [streamerHomeRoute()]: 'pages/public/homeStreamer/route.tsx',

  [advertiserHomeRoute()]: 'pages/public/homeAdvertiser/route.tsx',

  [holidays2024Route()]: 'pages/public/holidays2024/route.tsx',

  [privacyPolicyRoute()]: 'pages/public/privacyPolicy/route.tsx',

  [tosRoute()]: 'pages/public/termsOfService/route.tsx',

  [brandFormRoute()]: 'pages/public/brandForm/route.tsx',

  [thankYouRoute()]: 'pages/public/thankYou/route.tsx',

  [twitchconRoute()]: 'pages/public/twitchcon/route.tsx',

  [robotsTxtRoute()]: 'pages/public/robotsTxt.tsx',

  [sitemapRoute()]: 'pages/public/sitemapXml.tsx',

  [campaignLinkRoute(':linkId', ':slug')]: 'pages/public/campaignLink.tsx',

  [obsOverlayRoute(':streamerPublicId')]: 'pages/public/obsOverlay.tsx',

  [obsOverlayNextCampaignRoute(':streamerPublicId')]:
    'pages/public/obsOverlayNextCampaign.tsx',

  [obsOverlayVideoRoute(
    ':streamerPublicId',
    ':campaignPublicId',
    ':fingerprint'
  )]: 'pages/public/obsOverlayVideo.tsx',

  [obsOverlayEventsRoute(':streamerPublicId')]:
    'pages/public/obsOverlayEvents.tsx'
};
